import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import Title from "../banners/ServicesTitle"
import {FaUtensils} from 'react-icons/fa'

const Menu = ({items}) => {

  return (
    <MenuWrapper>
      <div className="section-center">
        {items && items.map(menuItem => {
          const { id, title, image, description } = menuItem.node
          return (
            <article key={id} className="menu-item">
              <Img fluid={image.fluid} alt={title} className="photo" />
              <div className="item-info">
                <header>
                  <h4>{title}</h4>
                  {/* <h4 className="price">${price}</h4> */}
                </header>
                <p className="item-text">{description.description}</p>
              </div>
            </article>
          )
        })}
      </div>
    </MenuWrapper>
  )
}

const MenuWrapper = styled.div`
  color: hsl(209, 61%, 16%);
  line-height: 1.5;

  .section-center {
    width: 90vw;
    margin: 0 auto;
    max-width: 1170px;
    display: grid;
    gap: 3rem 2rem;
    justify-items: center;
  }
  .menu-item {
    display: grid;
    gap: 1rem 2rem;
    max-width: 25rem;
  }
  .photo {
    object-fit: cover;
    height: 200px;
    width: 100%;
    border: 0.25rem solid var(--maincolor);
    border-radius: 0.25rem;
    
    display: block;
  }
  .item-info header {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px dotted hsl(210, 22%, 49%);
  }
  .item-info h4 {
    margin-bottom: 0.5rem;
  }
  .price {
    color: var(--maincolor);
  }
  .item-text {
    margin-bottom: 0;
    padding-top: 1rem;
    font-size: 1rem;
  }

  @media screen and (min-width: 768px) {
    .menu-item {
      grid-template-columns: 225px 1fr;
      gap: 0 1.25rem;
      max-width: 40rem;
    }
    .photo {
      height: 175px;
    }
  }
  @media screen and (min-width: 1200px) {
    .section-center {
      width: 95vw;
      grid-template-columns: 1fr 1fr;
    }
    .photo {
      height: 150px;
    }
  }
`

const Categories = ({ categories, filterItems }) => {
  return (
    <CategoriesWrapper>
      <div className="btn-container">
        {categories.map((category, index) => {
          return (
            <button
              type="button"
              className="filter-btn"
              key={index}
              onClick={() => filterItems(category)}
            >
              {category}
            </button>
          )
        })}
      </div>
    </CategoriesWrapper>
  )
}

const CategoriesWrapper = styled.div`
  .btn-container {
    margin-bottom: 3rem;

    text-align: center;
    width: 99%;
  }
  .filter-btn {
    background: transparent;
    border-color: transparent;
    font-size: 1rem;
    text-transform: capitalize;
    margin: 0.25rem 0.5rem;
    letter-spacing: 1px;
    padding: 0.375rem 0.25rem;
    color: var(--maincolor);
    cursor: pointer;
    transition: all 0.3s linear;
    border-radius: 0.25rem;
   
  }
  .filter-btn:hover {
    background: var(--maincolor);
    color: var(--secondarycolor);
  }
`


function App() {

  const contData1 = useStaticQuery(graphql`
  query {
    allContentfulMenu {
      edges {
        node {
          id
          title
          category
          price
          description {
            description
          }
          image {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`)


const items = contData1.allContentfulMenu.edges


  const allCategories = ["сите", ...new Set(items.map(item => item.node.category))]

  const [menuItems, setMenuItems] = useState(items)
  const [categories] = useState(allCategories)

  const filterItems = category => {
    if (category === "сите") {
      setMenuItems(items)
      return
    }
    const newItems = items.filter(item => item.node.category === category)
    setMenuItems(newItems)
  }

  return (
    <AppWrapper>
      <main style={{ minHeight: "100vh" }}>
        <section className="menu section">
          <div className="title">
            <Title maincolor title="Канцона Мени" icon={<FaUtensils/>}/>
          
          </div>
          <Categories categories={categories} filterItems={filterItems} />
          <Menu items={menuItems} />
        </section>
      </main>
    </AppWrapper>
  )
}

const AppWrapper = styled.div`
  

  /* section */
  .section {
    width: 90vw;
    margin: 0 auto;
    max-width: 1170px;
    /* max-width: var(--max-width); */
  }

  @media screen and (min-width: 992px) {
    .section {
      width: 95vw;
    }
  }

  .menu {
    padding: 5rem 0;
  }
  .title {
    text-align: center;
    margin-bottom: 2rem;
  }
  .underline {
    width: 5rem;
    height: 0.25rem;
    background: var(--maincolor);
    /* background: var(--clr-gold); */
    margin-left: auto;
    margin-right: auto;
  }
`

export default App
