import React from "react"
import { graphql } from "gatsby"
import PageHero from "../components/banners/PageHero"
import Menu from "../components/menu/index"

const MenuPage = ({ data }) => {
  console.log(data)
  return (
    <>
      <PageHero
        title="Канцона Мени"
        imgSrc={data.allContentfulPageSettings.nodes[0].pageMenuImage.fluid}
      />
      <Menu />
    </>
  )
}

export default MenuPage

export const query = graphql`
  query {
    allContentfulPageSettings {
      nodes {
        pageMenuImage {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`
